import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";

import Star from "../components/star";
import PageLayout from "../components/page-layout";
import { queryAll } from "../model/companies";

import * as style from "./q.module.css";

function Card({ name, rating }) {
  const stars = [];
  for (let i = 0; i < rating; i++) {
    stars.push(<Star key={i} className={style.star} />);
  }

  return (
    <Link to={`/company?name=${name.toLowerCase()}`} className={style.card}>
      <article>
        <span className={style.companyName}>{name}</span>
        {stars}
      </article>
    </Link>
  );
}

export default function DiversityPage({ location }) {
  const [companies, setCompanies] = useState(location?.state?.companies);

  useEffect(() => {
    if (!companies) {
      queryAll().then(setCompanies);
    }
  }, [companies]);

  return (
    <PageLayout>
      <section className={style.section}>
        {companies?.length &&
          companies.map((company, index) => <Card key={index} {...company} />)}
        {!companies?.length &&
          "Sorry we could not find matching companies in our database. Please try later."}
      </section>
    </PageLayout>
  );
}

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "bg.svg" }) {
      publicURL
    }
  }
`;
